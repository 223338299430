/* Fold ---------------- */
@media (max-width : 320px)
{
    .photoAdr {
        font-size: 12px;
    }
    .instlAndCID {
        font-size: 13px;
    }
    .mobileWidthCustom {
        width: 100%;
        padding: 3px !important;
    }
}

/* Smartphones  ---------------- */
@media (min-width : 321px) and (max-width : 767px){
    .mobileHiddenCustom {
        display: none;
    }
 
    .photoSiteNmFont {
        font-size: 17px;
    }
    .photoAdr {
        font-size: 14px;
    }
    .instlAndCID {
        font-size: 15px;
    }
    .mobileWidthCustom {
        width: 100%;
        padding: 3px !important;
    }
}

/* Tablets (portrait) -------------------- */
@media (min-width : 768px) and (max-width : 1024px) {

    .photoSiteNmFont {
        font-size: 20px;
    }
    .photoAdr {
        font-size: 17px;
    }
    .instlAndCID {
        font-size: 17px;
    }
    .cardCustom {
        padding: 10px 30px 20px 30px !important;
    }
    .mobileWidthCustom {
        width: 100%;
        padding: 3px !important;
    }
}

/* Old Desktops and laptops ------------------ */

@media (min-width : 1025px) {

    .photoSiteNmFont {
        font-size: 20px;
    }
    .photoAdr {
        font-size: 17px;
    }
    .instlAndCID {
        font-size: 18px;
    }
    .cardCustom {
      padding: 10px 30px 20px 30px !important;
    }
}

/* Desktops ------------------ */

@media (min-width : 1201px) {

}

.paperStickyCustom {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    position: sticky;
    top: 10px;
    padding: 2px 4px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 16px;
    z-index: 3;
}

.photoSiteNmFont {
    font-weight: 800;
    text-decoration-line: underline;
    text-decoration-color: #1565c0;
    text-decoration-thickness: 2px;
    line-height: 1.5;
}

.photoSiteKm {
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 800;
    font-size: 15px;
}

.instlAndCID {
    font-weight: 600;
    margin-top: 5px;
}
.colorHighlight {
    background: #dce9f9;
}

.clickMentCustom {
    margin-top: 15px;
    color: #1565c0;
    font-weight: 600;
    height: 20px
}
.mouseOverCustom {
    padding: 15px;
    height: 200px;
    border-radius: 10px;
    border: 3px dashed #1976d2;
    display: flex;
    justify-content: center;
    align-items: center;

}
.mouseOverCustom:hover {
    background: #a8c6d48c;
    cursor: pointer;
}
.cidsCustom:not(:last-of-type ):after {
    content: ",";
}
