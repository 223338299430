
.table>:not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 0px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

th {
  text-align: center !important;
}

.collapsed>tbody>tr>td{
  text-align: center !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: white !important;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: rgb(15 35 225 / 68%); 
}

.dataTables_wrapper .dataTables_processing, .dataTables_wrapper .dataTables_paginate {
    color: inherit;
    margin-top: 5px;
}

.dataTables_paginate{
  
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 1rem 0;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    background: #fff;
    font-weight: 400;
    border: 1px solid rgba(34,36,38,.15);
    -webkit-box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
    border-radius: 0.28571429rem;
    min-height: 2.85714286em;

}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: white !important;
  border: 1px solid white !important;
  background-color: #33a4bd5d !important;
}

.dataTables_wrapper .dataTables_paginate {
  float: right !important;
  text-align: right !important;
  padding-top: 0.11em !important;
  margin-top: 10px !important;
}

.filter-btn{
    height: 50px;
    width: 50px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  
  .content-header{
    background: linear-gradient(to right, #3dcdbc 0%, #33a1bd 100%);
    color:white;
    font-weight: 700;
    font-size:13px;
    height:30px;
    padding-top:5px;
  }

  .data-btn{
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 5px 15px;
    border-radius: 4px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 1px solid rgba(25, 118, 210, 0.5);
    color: rgb(25, 118, 210);
  }

  .excel-btn {    
    color:green;
    border-color: green;
    font-weight: 700;
  }

  .data-btn-highlight{
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 800;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 5px 15px;
    border-radius: 4px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 1px solid rgb(7, 50, 240);
    color: rgb(7, 50, 240);
  }

  button:disabled,button[disabled]{
    border: 1px solid #cecece;    
    color: #cccccc;
  }

  .data-btn-error{
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 5px 15px;
    border-radius: 4px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 1px solid rgba(167, 21, 21, 0.5);
    color: rgba(167, 21, 21, 0.5);
  }

  .table>:not(:last-child)>:last-child>* {
    border-bottom-color: rgb(225, 231, 231);
    border-bottom-width:0.01cm;
  }

  .table.dataTable>tbody>tr.child ul.dtr-details {
    display: inline-block;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }


.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    display: flex;
    align-items: center;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  display: inline-flex;
  align-items: center;
}

div.dataTables_processing>div:last-child>div {
  position: absolute;
  top: 0;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #3980b6;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  text-align: center;
}

table.dataTable>tbody>tr>td.select-checkbox:before, table.dataTable>tbody>tr>th.select-checkbox:before {
  content: " ";
  margin-top: -6px;
  margin-left: -6px;
  border: 1px solid black;
  border-radius: 3px;
}

table.dataTable>tbody>tr>td.select-checkbox:before, table.dataTable>tbody>tr>td.select-checkbox:after, table.dataTable>tbody>tr>th.select-checkbox:before, table.dataTable>tbody>tr>th.select-checkbox:after {
  display: block;
  
  top: 1.2em;
  left: 50%;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
}

table.dataTable tbody > .even{
  background-color: #e5e7e845!important
}

.ui.table>thead>tr>th { 
  background: #e4e8f0af !important;
}
@media only screen and (max-width: 767.98px){
  .sorting_1 {    
    text-align: center!important;
  }
}

.css-1q0vhp3-MuiGrid2-root {  
  padding: 0 !important;
}