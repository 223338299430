.pose-table > tbody > tr > td input {
    height: 35px;
    border: 1px #d1d0d0 solid;
    padding-left: 5px;
    padding-right: 5px;
}

.pose-table > tbody > tr > td select {
    height: 35px;
    border: 1px #d1d0d0 solid;
    padding-left: 5px;
    padding-right: 5px;
}

input.error, select.error {
    border: 2px #d30202 solid !important;
}

.pose-table > thead > tr > th {
    background-color: #f9fafb;
}

.css-19kzrtu {
    padding: 2px !important;
}