#dashboard-table-list > thead > tr > th {
    padding:0;
    height:31px;
    background-color:#f8f8f8 !important;
}

.box {
    background-color:rgb(231 240 254 / 46%);
    border-radius:10px;
}

.errCard {
    border:none;
    border-radius:10px;
    background-color:#930008;
    color:rgb(255, 255, 255);
}

.stateCard {
    border-radius:10px;
}

.errCard {
    height: 100%;
    padding: 10px;
    cursor: pointer;
}

.errCard > .header {
    height: 30%;
    overflow:hidden;     
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid rgb(223, 222, 222);    
    font-size:15px;
}

.errCard > .content-title{
    height: 30%;
    overflow:hidden;     
    text-overflow: ellipsis;
    white-space: nowrap;      
    margin-top:10px;
    font-size:15px;
}

.errCard > .content{
    height: 40%;
    overflow:hidden;     
    text-overflow: ellipsis;
    white-space: nowrap;      
    margin-top:5px;
    margin-bottom:5px;
    font-size:14px;
}
