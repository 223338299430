.category, .category *{
    margin:0;
    padding:0;
    color:#000;
}   
.category {
    position:absolute;
    overflow:hidden;
    top:10px;
    right:10px;
    width:152px;
    height:95px;
    z-index:10;
    border:1px solid rgb(187, 187, 187);
    font-family:'Malgun Gothic','맑은 고딕',sans-serif;
    font-size:12px;
    text-align:center;
    background-color:#fff;
}
.category .menu_selected {
    background:#455a94c7;
    color:#fff;
    border-left:1px solid #d8dde9;
} 
.category li{
    list-style:none;
    float:left;
    width:50px;
    height:95px;
    padding-top:5px;
    cursor:pointer;
    font-size:13px;
} 
.category .ico_comm {
    display:block;
    margin:3px 15px 4px;
    width:40px;
    height:37px;
} 
.category .ico_success {
    background:url('../image/pin_success.png') no-repeat;
}  
.category .ico_error {
    background:url('../image/pin_error.png') no-repeat;
}   
.category .ico_none {
    background:url('../image/pin_none.png') no-repeat;
} 
.map-dialog {
    position:absolute;
    overflow:hidden;
    right:11px;
    bottom: 35px;
    width:350px;
    height:250px;
    z-index:11;
    border: 1px solid gray;
    background-color: rgb(245, 248, 247, 0.9);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding:10px;        
}

.map-dialog.success {
    background-color:#08a000;
}

.map-dialog.error {
    background-color:#bd0101;
}

.map-dialog.none {
    background-color:#4e4e4e;
}

.map-dialog-header {
    width:295px;    
    padding-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color:white;
    font-size:16px;
}

.map-dialog-content {
    border-top:1px solid rgba(187, 184, 184, 0.404);
    padding-top: 10px;
    padding-left: 10px;    
    background:#fff;
    height:197px;      
}

.map-dialog-close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #eeebeb;
    width: 17px;
    height: 17px;
    background: url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/overlay_close.png');
}

.map-dialog-close:hover {
    cursor: pointer;
}

.map-dialog-content > .title {
    float: left;
    width:50%;
    color:#000;
    font-size:14px;    
    margin-bottom:10px;
}

.map-dialog-content > .node {
    float: right;
    width:50%;
    color:rgb(0, 0, 0);
    font-size:15px;   
    font-weight: 700; 
    margin-bottom:10px;
    overflow:hidden;     
    text-overflow: ellipsis;
    white-space: nowrap;
}

.map-dialog-content > .adr {
    float: left;
    width:310px;
    height:80px;
    color:rgb(8, 8, 8);
    font-size:15px;
    font-weight: 700;
    overflow:hidden; 
    word-break:break-all;
    text-overflow: ellipsis;
    white-space: nowrap;
}