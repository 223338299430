.jexcel_container {
    margin-top:10px;
}

.jdropdown {
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
    background: #fff;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
}

.jdropdown-focus {
    position: relative;
}

.jdropdown-close {
    display: none;
    font-size: 1em;
    color: var(--active-color);
    text-transform: uppercase;
    text-align: right;
    padding: 12px;
    font-weight: 700;
}

.jdropdown-content {
    min-width: inherit;
    border: 1px solid #8fb1e3;
    margin: 0;
    background-color: #fff;
    box-sizing: border-box;
    min-height: 10px;
    max-height: 215px;
    overflow-y: auto;
}

.jdropdown-default .jdropdown-container {
    background-color: #fff;
}

.jdropdown-focus .jdropdown-container {
    transform: translate3d(0,0,0);
}

.jdropdown-container {
    min-width: inherit;
    transform: translate3d(-10000px,0,0);
    position: absolute;
    z-index: 9001;
}

.jexcel .jdropdown-container-header {
    padding: 0;
    margin: 0;
    height: inherit;
}

.jdropdown-container-header {
    padding: 0;
    margin: 0;
    position: relative;
    box-sizing: border-box;
}

.jdropdown-default .jdropdown-item {
    padding: 4px;
    padding-left: 8px;
    padding-right: 40px;
}

.jdropdown-item {
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    overflow-x: hidden;
    color: #000;
    display: flex;
    align-items: center;
}

.jdropdown-default .jdropdown-item:hover {
    background-color: #1f93ff;
    color: #fff;
}

.jdropdown-default .jdropdown-selected {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIiAvPjxwYXRoIGQ9Ik05IDE2LjE3TDQuODMgMTJsLTEuNDIgMS40MUw5IDE5IDIxIDdsLTEuNDEtMS40MXoiIGZpbGw9IndoaXRlIiAvPjwvc3ZnPgo=);
    background-repeat: no-repeat;
    background-position: top 50% right 5px;
    background-color: #1f93ff !important;
    color: #fff;
}

.jdropdown-default .jdropdown-cursor {
    background-color: #eee;
}

.jcontextmenu.jcontextmenu-focus {
    display: inline-block;
}

.jcontextmenu {
    position: fixed;
    z-index: 10000;
    background: #fff;
    color: #555;
    font-size: 11px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-box-shadow: 2px 2px 2px 0 #8f9091;
    -moz-box-shadow: 2px 2px 2px 0 #8f9091;
    box-shadow: 2px 2px 2px 0 #8f9091;
    border: 1px solid #c6c6c6;
    padding: 0;
    padding-top: 4px;
    padding-bottom: 4px;
    margin: 0;
    outline: 0;
    display: none;
}

.jcontextmenu>div.header {
    display: none;
}

.jcontextmenu>div {
    box-sizing: border-box;
    display: flex;
    padding: 8px 8px 8px 32px;
    width: 250px;
    position: relative;
    cursor: default;
    font-size: 11px;
    font-family: sans-serif;
    text-align: left;
    -webkit-box-align: center;
    align-items: center;
}

.jcontextmenu>div {
    box-sizing: border-box;
    display: flex;
    padding: 8px 8px 8px 32px;
    width: 250px;
    position: relative;
    cursor: default;
    font-size: 11px;
    font-family: sans-serif;
    text-align: left;
    -webkit-box-align: center;
    align-items: center;
}

.jcontextmenu hr {
    border: 1px solid #e9e9e9;
    border-bottom: 0;
    margin-top: 5px;
    margin-bottom: 5px;
}

.jcontextmenu>div a {
    color: #555;
    text-decoration: none;
    flex: 1;
    cursor: pointer;
}

.jcontextmenu>div::before {
    content: attr(data-icon);
    font-family: 'Material Icons'!important;
    font-size: 15px;
    position: absolute;
    left: 9px;
    line-height: 24px;
}

.jcontextmenu>div span {
    margin-right: 10px;
    font-size: .9em;
}

.jcalendar {
    position: absolute;
    z-index: 9000;    
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: transparent;
    min-width: 280px;
}

.jcalendar .jcalendar-container {
    position: relative;
    box-sizing: border-box;
}

.jcalendar .jcalendar-content {
    position: absolute;
    z-index: 9001;
    -webkit-box-shadow: 1px 1px 5px 0 rgba(0,0,0,.39);
    -moz-box-shadow: 1px 1px 5px 0 rgba(0,0,0,.39);
    box-shadow: 1px 1px 5px 0 rgba(0,0,0,.39);
    background-color: #fff;
}

.jcalendar-controls {
    padding: 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: middle;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

.jcalendar-controls div {
    font-weight: 700;
}

.jcalendar-confirm, .jcalendar-reset {
    text-transform: uppercase;
    cursor: pointer;
    color: var(--active-color);
}

div {
    display: block;
}

.jcalendar-table {
    padding: 10px;
}

.jcalendar-table>table {
    width: 100%;
    background-color: #fff;
}

.jcalendar-table>table>thead {
    cursor: pointer;
}

.jcalendar-table>table>thead {
    cursor: pointer;
}

.jcalendar-table thead td {
    padding: 10px;
    height: 40px;
}

.jcalendar-header {
    text-align: center;
}

.jcalendar-header span {
    margin-right: 4px;
    font-size: 1.1em;
    font-weight: 700;
}

.jcalendar-prev {
    cursor: pointer;
    
    background-position: center;
    background-repeat: no-repeat;
}

.jcalendar-next {
    cursor: pointer;
    
    background-position: center;
    background-repeat: no-repeat;
}

.jcalendar-controls {
    padding: 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: middle;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

.jcalendar-time {
    display: flex;
}

.jcalendar-controls div {
    font-weight: 700;
}

.jcalendar-update {
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 4px;
    padding: 5px;
    width: 100%;
}

.jcalendar-container select:first-child {
    margin-right: 2px;
}

.jcalendar-container select {
    width: 55px;
    display: inline-block;
    border: 0;
    padding: 4px;
    text-align: center;
    font-size: 1.1em;
    user-select: none;
    margin-right: 10px;
}

.jcalendar-table>table>tbody td {
    box-sizing: border-box;
    cursor: pointer;
    padding: 9px;
    font-size: .9em;
}

.jcalendar-selected {
    background-color: #eee;
}

.jcalendar-reset {
    visibility: hidden;
    line-height: 0;
}

.jcalendar-reset:before {
    visibility: visible;
    content: '삭제';
    line-height:1.2em;
}

.jcalendar-confirm {
    visibility: hidden;
    line-height: 0;
}

.jcalendar-update {
    display: none;    
}

.jexcel > thead > tr > td {    
    background-color: rgb(236 239 245);    
}

.jexcel > thead > tr > td.selected {
    background-color: #c4e8ce;
}

.jexcel > tbody > tr.selected > td:first-child {
    background-color: #c4e8ce;
}

.jexcel > tbody > tr > td:first-child {
    position: relative;
    background-color: rgb(236 239 245);    
    text-align: center;
}

.jexcel_content::-webkit-scrollbar {
    height: 20px;
    background-color: black;
}