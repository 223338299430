.css-246fuu >.ps-menu-button {    
    color: #bac9fa !important;
}

.css-dip3t8 {
    background-color: rgb(249 249 249) !important;
}

.css-1wvake5.ps-broken {    
    z-index: 1001 !important;    
}

a .ps-open {
    font-weight:700 !important;
}

.ps-active {    
    font-weight:500 !important;
}

.ps-menu-button {  
    font-weight:500 !important;
    color: #000000 !important;
}

.css-crt1ee {
    margin-top: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
    color: rgb(0, 152, 229);
}

.css-1vmkajq {
    height: 64px;
    min-height: 64px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px 20px;
    margin-bottom: 24px;
    margin-top: 16px;
}

.css-1ha1l4a {
    margin-right: 4px !important;
}

li.active {
    background: linear-gradient(45deg, rgb(21 87 205 / 0%) 0%, rgb(255 164 90 / 37%) 100%);
}