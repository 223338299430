.treeview > .toggle {
    pointer-events: all;
    cursor: pointer;
  }

.treeview > .toggle:hover {
    color: #63b1de;
    fill: #63b1de;
}

.treeview svg {
  font-size: 17px;
}

.treeview span:hover {
  background-color: #cce2ff;
  border-radius: 15px;
  padding: 5px;
  font-weight: 600;
}

.treeview {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    line-height: 10px;
    font-size: 14px;
    /* overflow: hidden; */
  }
  