
.custom-checkbox{
    cursor: pointer; 
    position: absolute; 
    inset: 0px; 
    width: 32px; 
    height: 20px; 
    opacity: 0; 
    z-index: 2;
}

.css-1pr8naw {
    margin: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 22px;
    line-height: 44px;
    font-weight: 600;
}

.css-3vvtry{
    padding-left:3px;
}

.page-ready{
    background-image:url('../image/img_contents_ready.gif');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60% 60%;
}

.page-filter{
    border: 1px solid rgba(192, 192, 192, 0.603) !important;
    border-radius: 10px;
}

.css-79ws1d-MuiModal-root {    
    overflow: scroll;
}

.smModal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    background-color: white;
    border: 2px solid #000;
    box-shadow: 240px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;    
}

.Modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 800px;
    background-color: white;
    border: 2px solid #000;
    box-shadow: 240px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;        
    max-height: 800px;
    overflow-y: scroll;
}

.mdModal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background-color: white;
    border: 2px solid #000;
    box-shadow: 240px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;    
}

.lgModal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    background-color: white;
    border: 2px solid #000;
    box-shadow: 240px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;    
}

.bigModal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 1000px;
    background-color: white;
    border: 2px solid #000;
    box-shadow: 240px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    max-height: 900px;
    overflow-y: scroll;
}

.border-1{
    border: 1px solid silver
}

.label-1{
    background-color: #c0c0c030;
}

.ps-sidebar-container{
    position: absolute !important;
    height: 100% !important;
    width: 100% !important;    
    overflow-y: scroll !important;
}

.ps-sidebar-container::-webkit-scrollbar {
    width: 2px;
}

.ewqTBN {
    width: 95%;
}

.focus {
    border: 1px solid red;    
}

.text-lg {
    font-size: 2.55rem !important;
}

.text-sm {    
    font-size: 0.95rem !important;
    font-weight: 100;
}
.header_title {
    font-size: min(1.25rem, 2vmax) !important;
}

/*장애현황 css*/
.incident_sido_chip_style {
    width: 135px;
    height: 30px;
    margin: 2px;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #fffbf0;
}
.incident_sido_chip_style:hover {
    transition: all 0.2s linear;
    transform: scale(1.08) ;
    border: 2px solid #e0e0e0;
    z-index: 100;
    background: rgba(194, 237, 255, 0.26);
}
.incident_sido_chip_style.active{
    border-color: #1565c0;
    background-color:#1565c0;
    color: #fff;
    /*border-width: 2px;*/
    /*background: rgba(194, 237, 255, 0.26);*/
}
.incident_sido_long_text {
    width: 10ch;             /* 7글자 정도의 너비 */
    white-space: nowrap;    /* 텍스트가 줄 바꿈 없이 한 줄로 표시되도록 설정 */
    overflow: hidden;       /* 넘치는 텍스트를 숨김 */
    text-overflow: ellipsis; /* 넘치는 텍스트는 '...'으로 표시 */
}

.inc_diagram_modal {
    height: 100vh;
}
.inc_diagram_modal .Modal {
    /*height: min(90vh, 710px);
    height: max-content;
    max-height: 900px;*/
    max-height: min(96vh, 900px);
    height: max-content;
}

.incident_sigungu_chip_style {
    width: 135px;
    height: 30px;
    margin: 2px;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #fff5fe;
}
.incident_sigungu_chip_style:hover {
    transition: all 0.2s linear;
    transform: scale(1.08);
    border: 2px solid #e0e0e0;
    z-index: 100;
    background: rgba(194, 237, 255, 0.26);
}
.incident_sigungu_chip_style.active{
    border-color: #1565c0;
    background-color:#1565c0;
    color: #fff;
}

.sigungu_accordion {
    padding: 0 16px;
    background-color: #fff;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
}

.sigungu_accordion.open {
    max-height: 200px;
    padding: 16px;
    border-radius: 15px;
    border:1px solid #e0e0e0;
}
.incident_style {
    padding: 8px !important;
    cursor: pointer;
}

.activeColumn {
    background-color:#1565c0;
    color: #fff;
}

.activeColumn :first-of-type {
    border-radius: 10px 10px 0 0 !important;
}
#incident_table_2 {
    table-layout: fixed;
}
#incident_table_2 tbody tr {
    cursor: pointer;
}
#incident_table_2 tbody tr td {
    background: #fff;
    border-top: 1px solid #e0e0e0;
}
table thead tr .incident_fixed {
    background: #fff;
}

/*테이블 텍스트 겹침 방지*/
#incident_table_2 tbody tr td {
    word-wrap: break-word; /* 긴 단어를 줄바꿈 */
    white-space: normal; /* 줄바꿈 허용 */
    max-width: 400px;
}

/* 체크박스 선택시 배경 설정 */
#incident_table_2 tbody .incident_selected_row td{
    background: #44a4f3 !important;
    color: #fff !important;
}
#incident_table_2 tbody .incident_selected_row .font_blue {
    color: #fff !important;
}

#incident_table_1 thead tr th {
    padding-bottom: 3px;
    border-bottom: none !important;
}

td.font_blue {
    color: #298dff;
}
.incident_actn_table {
    max-height: 200px;
    overflow-y: auto;
}
.incident_actn_table thead {
    position: sticky;
    top: 0;
    z-index: 3;
    opacity: 1;
    background: rgba(228, 232, 240);
}

.incident_table_top_left {
    border-top: 1px solid rgba(34, 36, 38, .1);
    border-left: 1px solid rgba(34, 36, 38, .1);
}
.incident_table_right_bottom {
    border-right: 1px solid rgba(34, 36, 38, .1);
    border-bottom: 1px solid rgba(34, 36, 38, .1);
}
.incident_table_no_data {
    border-right: 1px solid rgba(34, 36, 38, .1);
    border-bottom: 1px solid rgba(34, 36, 38, .1);
    border-left: 1px solid rgba(34, 36, 38, .1);
    color: red;
}
.incident_table_del {
    color: red;
    font-weight: bold;
    cursor: pointer;
}
.incident_selected_row {
    color: #1565c0 !important;
}

/* 다중모달 테이블 y scroll*/
.incident_table_y_scroll {
    overflow-x: auto;
}
/* 장애대시보드 start */
.inc_main {
    color: rgb(68, 89, 110);
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    min-height: 500px;
    margin-left: -10px;
    margin-right: -10px;
}
.inc_map_accordian {
    position: absolute;
    bottom: -310px; /* 초기에는 화면 아래에 숨김 */
    left: 50%;
    transform: translateX(-50%);
    width: 95%; /* 부모 컨테이너의 최대 너비 조정 */
    transition: bottom 0.5s ease-in-out;
    z-index: 100;
    pointer-events: none;
}
.inc_map_accordian.open {
    bottom: 0;
}
.inc_map_pin_info {
    background: rgb(102, 152, 203);
    display: flex;
    width: 25vmax;
    height: min(7vmax, 80px);
    max-height: 100px;
    border-radius: 15px;
    border: 2px solid #fff;
}
.inc_map_pin_info>div:not(:first-child){
    border-left: 3px solid #fff;
}
.inc_dtl {
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    background: white;
    bottom: 0;
    height: 350px;
    pointer-events: auto;
}
.inc_info_Pin_box {
    height: 50%;
    padding: 5px;
    margin-right: calc(-10% - 9px);
}
.inc_info_Pin_style {
    object-fit:contain;
    width: 95%;
    height: 95%;
}
.inc_map_pin_text {
    text-align: center;
    padding-left: 4px;
    font-size: min(1.5vmax, 30px);
    color: #fff;
    font-weight: bold;
    text-shadow:  #6f6f6f  1px 0px, #6f6f6f  0px 1px, #6f6f6f  -1px 0px, #6f6f6f 0px -1px;
}
.inc_map_search_box {
    position: absolute;
    top: calc(5% + 20px);
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    width: 80%;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap !important;
}

.inc_site_search_fff>div {
    background: #fff;
}
.inc_map_search_icon {
    background: rgb(255, 255, 255, 1) !important;
    border: 1px solid rgba(34, 36, 38, .3) !important;
}
.inc_map_search_icon:hover {
    background: #f5fcff !important;
}
.inc_map_link_icon {
    background: rgb(255, 255, 255, 1) !important;
    border: 1px solid #1565c0 !important;
    width: 30px;
    height: 30px;
}
.inc_map_link_icon:hover {
    background: #f5fcff !important;
}
.inc_map_location_box {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding:1px;
    border-top: 1px dotted #e0e0e0;
    cursor: pointer;
}

.inc_map_location_box:hover {
    background: #f5fcff;
}
/* 장애대시보드 end */


@media (min-width: 768px) {
    /*테이블 헤더 고정*/
    table thead tr .incident_fixed:first-child {
        position: sticky !important;
        left: 0;
        z-index: 2;
        opacity: 1 !important;
    }

    table thead tr .incident_fixed:nth-child(2) {
        position: sticky !important;
        left: 80px;
        z-index: 2;
        opacity: 1 !important;
        /*backdrop-filter: blur(20px);*/
    }

    /*테이블 바디 고정*/
    #incident_table_2 tbody tr .incident_fixed:first-child {
        position: sticky !important;
        left: 0;
        z-index: 2;
        opacity: 1 !important;
        /*backdrop-filter: blur(20px);*/
    }

    #incident_table_2 tbody tr .incident_fixed:nth-child(2) {
        position: sticky !important;
        left: 80px;
        z-index: 2;
        opacity: 1 !important;
        /*backdrop-filter: blur(20px);*/
    }

    /* 컬럼 fixed시 border 생성 */
    .incident_bd_right {
        border-right: 1px dotted #e0e0e0;
    }
}
@media print {
    @page {
        size: A4;
        margin: 10mm;
        margin-top: 20mm;
        margin-bottom: 0mm;
    }

    @page :first {
        margin:0 1.3cm;
        margin-top: 20mm;
    }

    table {
        width: 210mm;    
        height: 297mm;         
    }    

    table tr td{
        padding:15px;
    }

    .print-ref{
        display: block !important;        
    }

    .title{        
        justify-content: center;
        align-items: center;
        font-size: 22px;
        font-weight: 600;        
        border: none;   
        text-align: center;           
    }

    .cell {
        height: 60px;
        padding: 15px;
        border: 1px solid silver;
    }

    .lh-cell {
        height: 40px;
        padding: 15px;
        border: 1px solid silver;
    }

    .lh-label-cell {
        height: 40px;
        padding: 15px;
        border: 1px solid silver;
        background-color:gainsboro;
        font-weight: 700;
    }

    tr.page-break  { display: block; page-break-before: always; }

}

@media screen {
	.print-ref {
		display: none !important;
	}    
}
