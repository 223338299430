.page-header {
    background-color: #f9f9f9;
    -webkit-box-shadow: 0 0 28px 0 rgb(86 61 124 / 13%);
    box-shadow: 0 0 28px 0 rgb(86 61 124 / 13%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 50px;
    position: relative;
    z-index: 1000;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .css-1x7skt0 {
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0 0 28px 0 rgb(86 61 124 / 13%) !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    flex-shrink: 0;
    position: static;
    background-color: #f9f9f9 !important;
    z-index: 10;
    color: rgb(0, 0, 0) !important;
}

.css-hyum1k-MuiToolbar-root {
    min-height: 50px !important;
}

.css-hip9hq-MuiPaper-root-MuiAppBar-root {  
  background-color: #f9f9f9 !important;
  color: #6f6f6f !important;
  border-color: gray !important;
  box-shadow:none !important;
}

.css-1juivf6-MuiTypography-root {  
  font-weight: 700 !important;
  color:#000000b0 !important;
}

.desc{
  font-weight: 600;
  font-size: 14px;
}